import { render, staticRenderFns } from "./ar.vue?vue&type=template&id=5b62592d"
import script from "./ar.vue?vue&type=script&lang=js"
export * from "./ar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ar.vue?vue&type=custom&index=0&blockType=div&class=home%20page-template-default%20page%20page-id-13%20wp-custom-logo%20wp-embed-responsive%20post-image-aligned-center%20sticky-menu-fade%20sticky-enabled%20both-sticky-menu%20no-sidebar%20nav-below-header%20separate-containers%20header-aligned-left%20dropdown-hover"
if (typeof block0 === 'function') block0(component)

export default component.exports